import {field} from "../../../../generic/tables";
import React from "react";
import {showDoubleRow} from "./utils";
import {sailingExperienceFromSlotToArrival} from "sv-common/functions/formatters";
import {t} from "i18next";


export const getPracticeSet = () => ([
    field(
        t('common.productOrderList.name'),
        false,
        "client_id.first_name",
        res => showDoubleRow(
            res.paid_for_ids, 'first_name', res.id, res.arrival_id.start_date, res.arrival_id.end_date, true
        ),
        'pt-2 pb-2'),
    field(t('common.productOrderList.lastname'), false, "client_id.last_name",
        res => showDoubleRow(res.paid_for_ids, 'last_name'), 'pt-2 pb-2'),
    field(t('common.productOrderList.phone'), false, "client_id.phone",
        res => {
            if (res.contact_phone && res.paid_for_ids.length) {
              res.paid_for_ids[0].phone = res.contact_phone;
            }
            return showDoubleRow(res.paid_for_ids, 'phone')
        }, 'pt-2 pb-2'),
    field(t('common.productOrderList.count'), false, "paid_for_length"),
    field(t('common.productOrderList.comment'), false, "comment", res =>
        <>{res?.comment?.match(/.{1,50}/g)?.map(line => <div>{line}</div>)}</>
    ),
    field(t('common.productOrderList.sailingExperience'), false, "client_id.sailing_experience",
        res => sailingExperienceFromSlotToArrival(res.client_id?.sailing_experience)),
    field(t('common.productOrderList.email'), false, "client_id.email",
        res => showDoubleRow(res.paid_for_ids, 'email'), 'pt-2 pb-2'),
])