import React from 'react';
import {Button, Col, PopoverBody, Row, UncontrolledPopover} from "reactstrap";
import {field} from "../../../../generic/tables";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {LoadBadge} from "../../../../generic/badges";
import {FaRegCommentAlt, FaTrash} from 'react-icons/fa'
import {CopyLink} from "../../../../generic/buttons";
import {ElementWithPermissions} from "../../../../common/formElements";
import {getPermissions} from "../../../../../lib/utils/hooks/useRoleSystem";
import { getPermissionEntity } from './helpers';
import {AdventureRemoveRuleProvider} from "../../../RuleProvider";

dayjs.extend(customParseFormat)

export const lowerLinkedFields = (getArrivalUrl, deleteArrival, typeForRule) => [
    field("Заезд", true, "start_date", res =>
        <Row>
            <Col>
                Мест: {res.total_sits - res.available_sits}/{res.total_sits} <LoadBadge value={res.booked_to_total}/>
            </Col>
        </Row>),
    field("Имя", true, "name", res => <Row><Col>{res.linked_arrival_id.name}</Col></Row>),
    field("Цена", true, "price_value", res => <Row>
        <Col>{res.linked_arrival_id.price_value} {res.linked_arrival_id.price_currency}</Col>
    </Row>),
    field("Записи", true, "booked_to_total", res =>
        <Row>
            <Col xs={12}>
                {res.linked_arrival_id.booked_sits}
            </Col>
        </Row>),
    field("Ссылка", false, "url", res => <CopyLink showLink={false} href={getArrivalUrl(res.linked_arrival_id)}/>),
    field("", false, "comment", res =>
        <Row>
            <Col>
                {!!res.linked_arrival_id.comment && <>
                    <FaRegCommentAlt id={"comment-" + res.linked_arrival_id.id}/>
                    <UncontrolledPopover placement="left" target={"comment-" + res.linked_arrival_id.id} trigger={'hover'}>
                        <PopoverBody>{res.linked_arrival_id.comment}</PopoverBody>
                    </UncontrolledPopover>
                </>}
            </Col>
        </Row>),
    field("", false, "comment", res =>
        <AdventureRemoveRuleProvider typeForRule={typeForRule} isArrivals>
            <Row>
                <Col>
                    <div className="btn-group">
                        <ElementWithPermissions disabled={!getPermissions(getPermissionEntity(res.adventure_id)).edit}>
                            <Button color="danger" size="xs" onClick={() => deleteArrival(res.linked_arrival_id)}>
                                <FaTrash/>
                            </Button>
                        </ElementWithPermissions>
                    </div>
                </Col>
            </Row>
        </AdventureRemoveRuleProvider>),
]



