import dayjs from "dayjs";
import {Col, PopoverBody, Row, UncontrolledPopover} from "reactstrap";
import {BiCake} from "react-icons/bi";
import React from "react";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import {FaCopy} from "react-icons/all";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import {withLinkPhone} from "../../../../../lib/utils/helpers/interfaceFeatures";
import {t} from "i18next";

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

const showSuccessToast = e => toast(e, {type: 'success'});


export const showDoubleRow = (paidForIds, fieldKey, orderId, startDate, endDate, isShowBirthDate) => {

    const isUserBirthday = (userBirthDate) => {
        if (!userBirthDate) return false;
        startDate = dayjs(startDate)
        endDate = dayjs(endDate)
        userBirthDate = dayjs(userBirthDate)

        return (
            (dayjs(userBirthDate.set('y', startDate.get('y'))) .isBetween(startDate, endDate, 'day', '[]')
                || dayjs(userBirthDate.set('y', endDate.get('y'))).isBetween(startDate, endDate, 'day', '[]'))
            && isShowBirthDate
        )
    }

    return <>
        <Row>
            <Col xs={12}>
                <div className="d-flex align-items-center">
                    {paidForIds[0]
                        ? withLinkPhone(paidForIds[0][fieldKey], fieldKey)
                        : ''
                    }
                    {isUserBirthday(paidForIds[0]?.birthdate) && <>
                        <BiCake className="ml-2" color="#eb3464"
                                id={`popover-target-${paidForIds[0]?.id }-${orderId ? `${orderId}` : ''}`}
                        />
                        <UncontrolledPopover placement="bottom" trigger={'hover'}
                                             target={`popover-target-${paidForIds[0]?.id }-${orderId ? `${orderId}` : ''}`}
                        >
                            <PopoverBody>{paidForIds[0]?.birthdate}</PopoverBody>
                        </UncontrolledPopover>
                    </>}
                </div>
            </Col>
        </Row>
        {paidForIds[1] && <hr className={'mt-2 mb-2'}/>}
        {paidForIds[1]
            ? <Row>
                <Col xs={12}>
                    {withLinkPhone(paidForIds[1][fieldKey] || '', fieldKey)}
                    {isUserBirthday(paidForIds[1].birthdate) && <>
                        <BiCake className="ml-2" color="#eb3464"
                                id={`popover-target-${paidForIds[1].id}-${orderId ? `${orderId}` : ''}`}
                        />
                        <UncontrolledPopover placement="bottom" trigger={'hover'}
                                             target={`popover-target-${paidForIds[1].id}-${orderId ? `${orderId}` : ''}`}
                        >
                            <PopoverBody>{paidForIds[1].birthdate}</PopoverBody>
                        </UncontrolledPopover>
                    </>}
                </Col>
            </Row>
            : <></>
        }
    </>
}


export const showWithCopy = (text) => {
    return <>
        {text}
        <CopyToClipboard text={text}
                         onCopy={() => showSuccessToast(t('common.textCopied'))}>
            <button className="btn btn-link p-0 mb-1 ml-1" onSubmit={e => e?.preventDefault()}
                    onClick={e => e?.preventDefault()}><FaCopy/></button>
        </CopyToClipboard>
    </>
}