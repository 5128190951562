import {field} from "../../../../generic/tables";
import React from "react";
import {showDoubleRow} from "./utils";
import {
  sailExperience,
  sailingExperienceFromRuToEN,
  sailingExperienceFromSlotToArrival
} from 'sv-common/functions/formatters';
import i18next from "i18next";
import ElementWithAccessInCountries from '../../../../../lib/utils/hocs/ElementWithAccessInCountries';
import { Col, Row } from 'reactstrap';
import { getLevelBadges } from './SlotSet';
import getCountryValue from '../../../../../lib/utils/getCountryValue';

const mapSailingExperience = (paidForIds) => {
    return paidForIds.map(item => {
        let resItem = {...item};

        if (item["sailing_experience"]) {
          const handler = getCountryValue({ en: sailingExperienceFromRuToEN, ru: sailingExperienceFromSlotToArrival });
          resItem["sailing_experience"] = handler(item["sailing_experience"]);
        }
        return resItem
    })
}

export const getArrivalSet = () => {
  const { t } = i18next;
  return ([
    field(t('common.productOrderList.name'), false, "client_id.first_name", res => showDoubleRow(res.paid_for_ids, 'first_name', res.id, res.arrival_id.start_date, res.arrival_id.end_date, true), 'pt-2 pb-2'),
    field(t('common.productOrderList.lastname'), false, "client_id.last_name", res => showDoubleRow(res.paid_for_ids, 'last_name'), 'pt-2 pb-2'),
    field(t('common.productOrderList.phone'), false, "client_id.phone", res => {
      if (res.contact_phone && res.paid_for_ids.length) {
        res.paid_for_ids[0].phone = res.contact_phone;
      }
      return showDoubleRow(res.paid_for_ids, 'phone')
    }, 'pt-2 pb-2'),
    field(t('common.productOrderList.count'), false, "paid_for_length"),
    field(t('common.productOrderList.comment'), false, "comment", res =>
      <>{res?.comment?.match(/.{1,50}/g)?.map(line => <div>{line}</div>)}</>
    ),
    field(t('common.productOrderList.sailingExperience'), false, "client_id.sailing_experience", res => <>
        <ElementWithAccessInCountries hideInCountries={['RU']}>
          <Row>
            {getLevelBadges(res.paid_for_ids[0]?.sailing_experience || '0/7')}
              <Col>
                - {t(sailExperience[res.paid_for_ids[0]?.sailing_experience || '0/7'])}
              </Col>
          </Row>
        </ElementWithAccessInCountries>
        <ElementWithAccessInCountries hideInCountries={['EN']}>
          {showDoubleRow(mapSailingExperience(res.paid_for_ids), 'sailing_experience')}
        </ElementWithAccessInCountries>
      </>, 'pt-2 pb-2'),
    field(t('common.productOrderList.email'), false, "client_id.email", res => showDoubleRow(res.paid_for_ids, 'email'), 'pt-2 pb-2'),
  ])
}