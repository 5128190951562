import {Controller} from "react-hook-form";
import {requiredRule} from "../../../common/reactHookFormsFields/rules";
import FieldWithValidation from "../../../generic/fields/FieldWithValidation";
import {t} from "i18next";
import {FormGroup} from "reactstrap";
import {Dropzone} from "../../../generic/fields";

const DropzoneRHF = ({form}) => {
    const {control, errors} = form;

    return <FormGroup>
        <Controller
            control={control}
            name="logo"
            defaultValue=""
            rules={requiredRule}
            render={(
                {value, onBlur, name, ref, onChange},
                {invalid}
            ) =>
                <FieldWithValidation invalid={invalid} errors={errors} name={name} isRequired
                                     label={t('constructor.templates.logo')}
                >
                    <div style={{border: invalid ? '1px solid #dc3545' : '0'}}
                         className={invalid ? 'is-invalid' : ''}>
                        <Dropzone name={'logo'} extFile={value} onBlur={onBlur} callback={onChange} />
                    </div>
                </FieldWithValidation>
            }></Controller>
    </FormGroup>
}

export default DropzoneRHF