import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {SelectControllerRHF} from "../../../generic/fields";
import i18next, {t} from "i18next";
import {COUNTRY} from "../../../../lib/utils/constants";

const TimeZonePicker = ({form, state, setState, isRequired = true,
                        label = t('common.forms.timeZonePicker.label') }) => {
    const {language} = i18next;

    const TIMEZONES_OPTIONS = useMemo(() => [
        <option value={''} key={'+1'} disabled>{t('common.timeZone.pickZone')}</option>,
        <option value={'+0200'} key={'+2'}>{t('common.timeZone.kaliningrad')}</option>,
        <option value={'+0300'} key={'+3'}>{t('common.timeZone.moscow')}</option>,
        <option value={'+0400'} key={'+4'}>
            {COUNTRY === 'EN' ? t('common.timeZone.dubai') : t('common.timeZone.samara')}
        </option>,
        <option value={'+0500'} key={'+5'}>{t('common.timeZone.ekaterinburg')}</option>,
        <option value={'+0600'} key={'+6'}>{t('common.timeZone.omsk')}</option>,
        <option value={'+0700'} key={'+7'}>{t('common.timeZone.krasnoyarsk')}</option>,
        <option value={'+0800'} key={'+8'}>{t('common.timeZone.irkutsk')}</option>,
        <option value={'+0900'} key={'+9'}>{t('common.timeZone.yakutsk')}</option>,
        <option value={'+1000'} key={'+10'}>{t('common.timeZone.krasnoyarsk')}</option>,
        <option value={'+1100'} key={'+11'}>{t('common.timeZone.vladivostok')}</option>,
        <option value={'+1200'} key={'+12'}>{t('common.timeZone.kamchatka')}</option>,
    ], [language])

    const {control, errors} = form;
    return  <SelectControllerRHF options={TIMEZONES_OPTIONS} inputName={'timezone'} control={control}
                                 setValue={setState} errors={errors} rules={isRequired ? {required: t('inputs.validation.required')}: {}}
                                 label={label} isRequired={isRequired} defaultValue={state.timezone || ''}/>

}

TimeZonePicker.propTypes = {
    form: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    isRequired: PropTypes.bool,
};
export default TimeZonePicker;